import styles from './CardViewer.module.css'
import PresenterPageCard from './PresenterPageCard'
import PresenterProjectCard from './PresenterProjectCard'
import { getUrlEntityA } from './Url'
import { Link } from 'react-router-dom'
import { GoInfo } from "react-icons/go";
// Отображает карточку
function CardViewer(props){
    let fragment = <>default card viewer</>
    let link_to = '_'
    if (props.entity === 'pages'){
        const {page_title, page_content} = props
        link_to = 'page'
        fragment = <PresenterPageCard page_title={page_title} page_content={page_content}></PresenterPageCard>
    }

    if (props.entity === 'projects'){
        const {project_name, project_description} = props
        link_to = 'project'
        fragment = <PresenterProjectCard project_name={project_name} project_description={project_description}></PresenterProjectCard>
    }

    return ( <div className={styles.CardViewer}>
                {fragment}
            <div>
             <Link to={getUrlEntityA(link_to, props.ob_id)}><GoInfo /></Link>
             </div>
            </div> )
}

export default CardViewer