import { Outlet } from 'react-router-dom';
import styles from './MainLayout.module.css'
import Menu from './Menu';
import Header from './Header';
import Footer from './Footer';
import BlockGroup from './BlockGroup';
import SearchForm from './SearchForm';
 
// Главный макет
 
function MainLayout( ){
   
    return ( <div className={styles.MainLayout}>
        <Menu></Menu>
        <Header></Header>
        <SearchForm></SearchForm>
       
        <Outlet></Outlet>
        <BlockGroup items={['static', 'nodejs', 'php']}></BlockGroup>
     
        <Footer></Footer>
    </div> )
}


export default MainLayout