import { getSingleEntity } from './Model';
import styles from './SingleViewer.module.css'
import { useParams} from 'react-router-dom'
import PresenterPage from './PresenterPage';
import PresenterProject from './PresenterProject';
import { UseDocumentTitle } from './UseDocumentTitle'

 
// Отображает информацию об отдельном объекте
function SingleViewer(props){
    const params = useParams()
    let ob_id = null
     // Получаем ID объекта из параметра :ob_id 
    if (props.use_id === 'props') ob_id = props.ob_id; else ob_id = params.ob_id;
   
    
    // Выбрали данные "имитируем асинхронный запрос"
    const obj = getSingleEntity(props.entity, ob_id)
    // Если объекта нет
    if (!obj){
        return <div className={styles.SingleViewer}>No data about {props.entity} with {ob_id}</div>
    }
    let fragment = <div>Default fragment</div>
    // Если просматриваем страницы то вызываем PresenterPage
    if (props.entity === 'pages'){
        UseDocumentTitle(obj.page_title)
        fragment = <PresenterPage entity={props.entity} {...obj}></PresenterPage>
    }
 
    // Если просматриваем проекты то вызываем PresenterProject
    if (props.entity === 'projects'){
        UseDocumentTitle(obj.project_name)
        fragment = <PresenterProject entity={props.entity} {...obj}></PresenterProject>
    
        };



    // Вернем представление
        return (
            <div className={styles.SingleViewer}>
               {fragment}
              
            </div>
        )
}

export default SingleViewer