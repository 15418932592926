// Построитель ссылок

function getUrlList(entity){
    return `${entity}s`
}

function getUrlEntity(entity, entity_id){
    return `${entity}/${entity_id}`
}

function getUrlListA(entity){
    return `/${entity}s`
}

function getUrlEntityA(entity, entity_id){
    return `/${entity}/${entity_id}`
}

export { getUrlList, getUrlEntity, getUrlListA, getUrlEntityA  }