 

 

function HomePageContent(){
    return <>
       
       
    <h4>Создание интернет-магазина</h4>
    <p class="left-align light">Продажи в Интернете осуществляются путем размещения информации о товарах на серверах, которые практически постоянно подключены к сети Интернет для того, чтобы с предлагаемым ассортиментом пользователь мог ознакомиться из любой точки земного шара.</p>
  <p class="left-align light">Интернет-магазин представляет собой сочетание нескольких ключевых компонент - шаблонов дизайна для однотипной информации, интерактивных элементов для взаимодействия с пользователем, сторонних сервисов для проведения платежей, а также средств для хранения различной информации (описаний товаров, изображений и т.д.)</p>
      <p class="left-align light">После публикации интернет-магазина в сети необходимо настроить рекламную компанию в социальных сетях и поисковых системах, чтобы товарные предложения стали доступны пользователям, которые реально заинтересованы в приобретении товаров или услуг.</p>
 
  </>
}
 
export  default HomePageContent
 