import SearchResItem from "./SearchResItem"
import styles from './SearchResList.module.css'
import getData from "./Model"
import { getUrlEntityA } from "./Url"
// Компонент поиска
function SearchResList({query}){
    // Если пустой запрос - вернуть пустой фрагмент
    if (query==='') return ( <></> ) 
    // Получаем данные о проектах и о страницах
    const projects = getData('projects')
    const pages = getData('pages')
    // Массив под найденное
    let foundItems = []
    // Обход проектов
    for(let project of projects){
        // Если имя проекта содержит запрос
        // Добавить ссылку и добавить текст
        if (project.project_name.includes(query)) foundItems.push({
            'link' : getUrlEntityA('project', project.ob_id),
            'text' : project.project_name
        })
    }
    // Аналогично по страницам
    for(let page of pages){
         
        if (page.page_title.includes(query)) foundItems.push({
            'link' : getUrlEntityA('page', page.ob_id),
            'text' : page.page_title
        })
    }
    // Возврат представления  
    return (<div className={styles.SearchResList}>

    <h4>Найдено: {foundItems.length}</h4>    
    {foundItems.map((foundItem, index)=> <SearchResItem key={index} {...foundItem}></SearchResItem> )}
        

    </div>)
}

export default SearchResList