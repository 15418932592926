function ContactPageContent(){

    return <>
      <h5 class="white-text">Мессенджеры, ссылки</h5>
          <ul>
            <li><a class="white-text" href="whatsapp://send?phone=79878669270">Связаться по WhatsApp</a></li>
            <li><a class="white-text" href="viber://chat?number=%2B79878669270">Связаться по Viber</a></li>
            <li><a class="white-text" href="https://join.skype.com/invite/IHUnQJYxn9je">Звонок в Скайп</a></li>
            <li><a class="white-text" href="https://t.me/dev_artnazarov">Связаться через Telegram</a></li>
            <li><a class="white-text" href="https://matrix.to/#/@artnazarov:matrix.org">Связаться по Matrix</a></li>
            <li><a class="white-text" href="https://github.com/artnazarov">Github</a></li>
            <li><a class="white-text" href="https://nazarow.ru">личный блог</a></li>
          </ul>
    </>

}

export default ContactPageContent