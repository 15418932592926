// import logo from './logo.svg';
import './App.css';
// Импорт определителя маршрутов, фрагмента для маршрута и их коллекции
import { BrowserRouter, Route, Routes } from 'react-router-dom'
 
 
 
import MainLayout from './components/MainLayout';
import SingleViewer from './components/SingleViewer';
import ListViewer from './components/ListViewer';


function MutableApp(){
  

  return ( <>
  
  <div className="App">
             <Routes>
        <Route path="/" element={<MainLayout></MainLayout>}>
          <Route index={true} element={<SingleViewer use_id={"props"} entity="pages" ob_id="index" ></SingleViewer>}></Route>
          <Route path="pages" element={<ListViewer entity="pages"></ListViewer>}></Route>
          <Route path="page/:ob_id" element={<SingleViewer entity="pages"></SingleViewer>}></Route>
          <Route path="projects" element={<ListViewer entity="projects"></ListViewer>}></Route>
          <Route path="project/:ob_id" element={<SingleViewer entity="projects"></SingleViewer>}></Route>
        </Route>
       
        <Route path="*" element={<SingleViewer entity="pages" ob_id="page404"></SingleViewer>}></Route>
        </Routes>
    </div>
    
    </> )
}

function App() {

  return (  <BrowserRouter>
            <MutableApp></MutableApp>
     </BrowserRouter> 
  );
}

export default App;
