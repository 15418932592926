// Поставщик данных
// "База данных"
// Ссылки для меню

import { GoHome } from "react-icons/go";
import { GoBriefcase } from "react-icons/go";
import { GoBook } from "react-icons/go";
import { GoRocket } from "react-icons/go";
import { GoTools } from "react-icons/go";
import HomePageContent from "./HomePageContent";
import ServicesPageContent from "./ServicesPageContent";
import ContactPageContent from "./ContactPageContent";

const mainmenu = [ 
    { 
    'ob_id' : 1,
    'link_route'  : '/',
    'link_caption' : 'Главная',
    'icon' : <GoHome></GoHome>
   },
   { 
    'ob_id' : 2,
    'link_route'  : '/projects',
    'link_caption' : 'Проекты',
    'icon': <GoBriefcase />
   },
   { 
    'ob_id' : 3,
    'link_route'  : '/pages',
    'link_caption' : 'Страницы',
    'icon': <GoBook></GoBook>
   },
   { 
    'ob_id' : 3,
    'link_route'  : '/page/services',
    'link_caption' : 'Услуги',
    'icon' : <GoRocket></GoRocket>
   },
   { 
    'ob_id' : 4,
    'link_route'  : '/project/nzt',
    'link_caption' : 'Библиотека nzt',
    'icon': <GoTools></GoTools>
   },

 ];
// Страницы
const pages = [ 
    { 
    'ob_id' : 'index',
    'page_title'  : 'Главная страница',
    
    'page_content' : ( <>
        <ServicesPageContent></ServicesPageContent>   
    </> ) 
},

 { 
    'ob_id' : 'services',
    
    'page_title'  : 'Услуги',
    'page_content' : (
        <HomePageContent></HomePageContent>
    ),
},

{ 
    'ob_id' : 'contacts',
    
    'page_title'  : 'Обратная связь',
    'page_content' : (
        <>
            <ContactPageContent></ContactPageContent>
        </> 
    ),
},

 ];

// Проекты
const projects = [ { 
    'ob_id' : 'nzt',
    'project_name'  : 'Библиотека nzt-ts',
    'project_description' : 'Для тестирования , typescript',
    'repos' : [
        
        {
            'repoUrl' : 'https://github.com/ArtNazarov/nzt',
            'repoTitle' : 'Github'
        },

        {
            'repoUrl' : 'https://gitflic.ru/project/artnazarov/nzt',
            'repoTitle' : 'Gitflic reserve'
        },

        {
            'repoUrl' : 'https://gitverse.ru/ArtNazarov/nzt',
            'repoTitle' : 'GitVerse reserve'
        },
        {
            'repoUrl' : 'https://www.npmjs.com/package/nzt-ts',
            'repoTitle' : 'NPM'
        },
        {
            'repoUrl' : 'https://codeberg.org/ArtNazarov/nzt',
            'repoTitle' : 'CodeBerg'
        }


    ],

}, { 
    'ob_id' : 'nz_installer',
    'project_name'  : 'Библиотека nz_install',
    'project_description' : 'Для установки приложений в Linux, nodejs',
    'repos' : [
        {
            'repoUrl' : 'https://github.com/ArtNazarov/nz_installer',
            'repoTitle' : 'Github'
        },

        {
            'repoUrl' : 'https://www.npmjs.com/package/nz_installer',
            'repoTitle' : 'NPM'
        }

    ],

},

{ 
    'ob_id' : 'htmlbuilder',
    'project_name'  : 'HtmlBuilder',
    'project_description' : 'Генератор статических сайтов',
    'repos' : [
        {
            'repoUrl' : 'https://github.com/ArtNazarov/HtmlBuilder',
            'repoTitle' : 'Github'
        },

        {
            'repoUrl' : 'https://gitflic.ru/project/artnazarov/htmlbuilder',
            'repoTitle' : 'Gitflic reserve'
        },

        {
            'repoUrl' : 'https://gitverse.ru/ArtNazarov/HtmlBuilder',
            'repoTitle' : 'GitVerse reserve'
        },

        {
            'repoUrl' : 'https://codeberg.org/ArtNazarov/HtmlBuilder',
            'repoTitle' : 'CodeBerg'
        }


    ],
},

{ 
    'ob_id' : 'OneClickHostManager',
    'project_name'  : 'Программа One Click Host Manager',
    'project_description' : 'Для блокировки сайтов вроде нежелательной рекламы',
    'repos' : [
        
        {
            'repoUrl' : 'https://github.com/ArtNazarov/OneClickHostManager',
            'repoTitle' : 'Github'
        },

        {
            'repoUrl' : 'https://gitverse.ru/ArtNazarov/OneClickHostManager',
            'repoTitle' : 'GitVerse'
        },

        {
            'repoUrl' : 'https://gitflic.ru/project/artnazarov/oneclickhostmanager',
            'repoTitle' : 'GitFlic'
        },

        {
            'repoUrl' : 'https://codeberg.org/ArtNazarov/OneClickHostManager',
            'repoTitle' : 'CodeBerg'
        }


    ],

},


{ 
    'ob_id' : 'arch-post-installer',
    'project_name'  : 'Arch Post Installer',
    'project_description' : 'GUI для настройки Arch Linux',
    'repos' : [

        {
            'repoUrl' : 'https://github.com/ArtNazarov/arch-post-installer',
            'repoTitle' : 'Github'
        },

        {
            'repoUrl' : 'https://gitflic.ru/project/artnazarov/arch-post-installer',
            'repoTitle' : 'Gitflic reserve'
        },

        {
            'repoUrl' : 'https://gitverse.ru/ArtNazarov/arch-post-installer',
            'repoTitle' : 'GitVerse reserve'
        },

        {
            'repoUrl' : 'https://codeberg.org/ArtNazarov/arch-post-installer',
            'repoTitle' : 'CodeBerg'
        }


    ],
},

{ 
    'ob_id' : 'censorit-chrome-ext',
    'project_name'  : 'CensorIt Chrome Ext',
    'project_description' : 'Расширение для цензуры для браузеров на основе Chrome по списку ключевых слов',
    'repos' : [

        {
            'repoUrl' : 'https://github.com/ArtNazarov/censorit-chrome-ext',
            'repoTitle' : 'Github'
        },

        {
            'repoUrl' : 'https://codeberg.org/ArtNazarov/censorit-chrome-ext',
            'repoTitle' : 'CodeBerg reserve'
        },

    ],
},

{ 
    'ob_id' : 'censorit-firefox-addon',
    'project_name'  : 'CensorIt Firefox Addon',
    'project_description' : 'Расширение для цензуры для браузеров на основе Firefox по списку ключевых слов',
    'repos' : [

        {
            'repoUrl' : 'https://github.com/ArtNazarov/censorit-firefox-addon',
            'repoTitle' : 'Github'
        },

        {
            'repoUrl' : 'https://codeberg.org/ArtNazarov/censorit-firefox-addon',
            'repoTitle' : 'CodeBerg reserve'
        },

        {
            'repoUrl' : 'https://gitverse.ru/ArtNazarov/censorit-firefox-addon',
            'repoTitle' : 'GitVerse reserve'
        },

        {
            'repoUrl' : 'https://gitflic.ru/project/artnazarov/censorit-firefox-addon',
            'repoTitle' : 'GitFlic reserve'
        }


        

    ],
}

];

// Блоки
const blocks = [ { 
    'ob_id' : 'nodejs',
    'block_name'  : 'Разработка на nodes',
    'block_content' : 'Nodejs - это разновидность популярного языка JavaScript. Код, написанный на NodeJs, можно выполнять на сервере. NodeJS отличается высокой скоростью работы, имеет большую библиотеку компонентов и поддерживается сообществом разработчиков. Популярность этой платформы растет с каждым годом. Сайт на NodeJS будет превосходным решением, если вам необходимо обрабатывать много запросов от пользователей.',
},

{ 
    'ob_id' : 'php',
    'block_name'  : 'Разработка на PHP',
    'block_content' : 'PHP поддерживается большинством компаний, предоставляющих услуги по публикации сайтов в Интернете. Популярные системы управления сайтами, такие как OpenCart или Wordpress, написаны на PHP. Сайт на PHP - неплохое решение для старта в Интернете. ',
},

{ 'ob_id' : 'static',
    'block_name' : 'Статические сайты',
    'block_content' : 'Статические сайты создаются на языке HTML. Для удобства обслуживания ресурса современный статический сайт публикуется с помощью генератора статических сайтов, который играет роль базы данных и оборачивает данные в шаблоны для конечного представления пользователю. Статический сайт отличается большей скоростью работы и более высоким уровнем безопасности.'
}

];

// Получает список данных целиком
function getData(entity){
    let result = null
    if ('mainmenu' === entity) {
      
         result = mainmenu;

    };
    if ("pages" === entity) {
      
        result = pages

   };

   if ("projects" === entity) {
      
    result = projects

};

if ("blocks" === entity) {
      
    result = blocks

};
   
    return result;
}
// "Выборка as SELECT WHERE ob_id"
function getSingleEntity(entity, ob_id){
    const data = getData(entity)
    if (!data) return null;
    const obj = data.find((elem) => {return elem.ob_id === ob_id})
    // будет null если не найден
    return obj
}

export { getData, getSingleEntity }
export default getData