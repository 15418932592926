import styles from './Menu.module.css'
import getData from './Model'
import MenuItem from './MenuItem'

// Компонент меню

function Menu(props){
    const mainmenu = getData('mainmenu')
    if (!mainmenu){
        return <div>No data for mainmenu</div>
    } 
    return ( <div className={styles.Menu}>

     <nav>
        {mainmenu.map(  ( menuitem, index ) => {return <MenuItem key={index} {...menuitem}></MenuItem>})}
    </nav>
    
    <div>{props.children}</div>
    </div>)

}

export default Menu