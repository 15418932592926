import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const SITE_NAME = "Сайт с проектами"

function UseDocumentTitle(title = "Главная страница") {
  const location = useLocation();

  useEffect(() => {
    document.title = `${SITE_NAME} - ${title}`;
  }, [location, title]);

  return null;
}

export { UseDocumentTitle }