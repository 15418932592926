import getData from './Model';
import CardViewer from './CardViewer';

import styles from './ListViewer.module.css'
import { UseDocumentTitle } from './UseDocumentTitle'

// Отображает список карточек с объектами типа entity
function ListViewer({entity}){
    let data = getData(entity)
    // console.log(data)
    if (entity === 'projects') UseDocumentTitle('Проекты')
    if (entity === 'pages') UseDocumentTitle('Страницы')
        
    return ( <div className={styles.ListViewer}>
        {
        data.map( (item) => <CardViewer entity={entity} key={item.ob_id} {...item}></CardViewer>)
        }
    </div> )
}
export default ListViewer