import { Link } from "react-router-dom"
import { getUrlListA } from "./Url"
import { GoStack } from "react-icons/go";
import styles from './PresenterPage.module.css'
// Представление страницы, встраивается в SingleViewer
function PresenterPage({page_title, page_content}){
    // Вернем фрагменты
    return (
        <>
            <h1 className={styles.page_title}>{page_title}</h1>
            <div className={styles.page_content}>{page_content}</div>
            <Link to={getUrlListA('page')}><GoStack /></Link>
        </>
    )
}

export default PresenterPage