import { useState } from "react"
import { GoSearch } from "react-icons/go";
import styles from './SearchForm.module.css'
import SearchResList from "./SearchResList";

// Форма поиска

function SearchForm(){
 // начальное состояние - поисковая фраза пустая
 const initialState = ''
    // Состояние компонента логин и пароль с их сеттером,
    // начальное через хук useState
    const [searchText, setSearchText] = useState(initialState)
    // мутатор для поиска
    function handleSearchText(event){
        setSearchText( event.target.value )
    }

     

    return ( <div className={styles.SearchForm}><label><GoSearch />Поиск</label>
          <input data-testid="searchText" placeholder="Поиск" value={searchText} name="searchText"
        onChange={handleSearchText}></input>
        
      
        {!!searchText && <SearchResList query={searchText}></SearchResList> }
    </div>)
}

export default SearchForm