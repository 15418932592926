import styles from './PresenterPageCard.module.css'

function PresenterPageCard({page_title}){
    return (
        <>
            <h3 className={styles.page_title}>{page_title}</h3>
        </>
    )
}

export default PresenterPageCard