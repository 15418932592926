import { getSingleEntity } from "./Model"
import styles from './Block.module.css'
function Block(props){
    let data = getSingleEntity(props.entity, props.ob_id)
    if (!data) {
        return <div> No such block </div>
    }
    return ( <div className={styles.Block}>
        <h5 className={styles.block_name}>{data.block_name}</h5>
        <p className={styles.block_content}>{data.block_content}</p>
    </div> )
}

export default Block