import { Link } from "react-router-dom"
import { getUrlListA } from "./Url"
import { GoStack } from "react-icons/go";
import styles from './PresenterProject.module.css'
import ProjectLinkGroup from "./ProjectLinkGroup";

function PresenterProject({project_name, project_description, repos}){
    
    return (
        <>
            <h1 className={styles.project_name}>{project_name}</h1>
            <p className={styles.project_description}>{project_description}</p>
            <ProjectLinkGroup repos={repos}></ProjectLinkGroup>
            <Link to={getUrlListA('project')}><GoStack /></Link> 
        </>
    )
}

export default PresenterProject

