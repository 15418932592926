function ServicesPageContent(){
    return <>
    <h4>Базовые работы</h4>
        <ol>
        <li>Регистрация и подключение домена (адрес сайта в интернете).</li>
        <li>Настройка почты для домена</li>
        <li> Установка системы управления сайтом</li>
        <li> Установка шаблона оформления для системы управления сайтом</li>
        <li> Подключение формы для обратной связи, сбора заявок</li>
        <li> Подключение приема платежей, тестирование приема оплаты эквайринг</li>
        <li> Размещение основной информации Заказчика на сайте (контактные данные и прочее)</li>
        </ol>
    </>
}

export default ServicesPageContent