import ProjectLinkItem from "./ProjectLinkItem"
import styles from './ProjectLinkGroup.module.css'
function ProjectLinkGroup({repos}){
    return ( <div className={styles.ProjectLinkGroup}>
        <h5>Репозитории с исходным кодом</h5>
        <div className={styles.ProjectLinkGroupItems}>
            {repos.map((repo, index)=> <ProjectLinkItem key={index} {...repo}></ProjectLinkItem> )}
        </div>
    </div>)
}

export default ProjectLinkGroup